<template>
	<div>
	</div>
</template>

<script>
// @ is an alias to /src
export default {
	name: "Home",
};
</script>
